<template>
  <div>
    <lista-usuarios-cliente/>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BButton, } from "bootstrap-vue";
import ListaUsuariosCliente from './ListaUsuariosCliente.vue';
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    ListaUsuariosCliente,
  }
}
</script>

<style>

</style>