<template>
  <div>
    <b-card>
      <filtros-usuarios-cliente/>
      <b-table
        hover
        striped
        responsive
        :items="usuarios.data"
        :fields="fields"
      >

        <template v-slot:cell(deleted_at)="data">
          <span v-if="data.value"> <b-badge variant="danger">Sim</b-badge></span>
          <span v-else><b-badge variant="success">Não</b-badge></span>
        </template>

      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="usuarios.total"
        :per-page="perPage"
        align="center"
        @input="paginacao"
      />
    </b-card>
  </div>
</template>

<script>
import {BRow, BCol, BCard, BButton, BTable, BPagination, BBadge,} from "bootstrap-vue";
import { mapState } from 'vuex';
import FiltrosUsuariosCliente from './FiltrosUsuariosCliente.vue';
export default {
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BPagination,
    FiltrosUsuariosCliente,
  },
  data() {
    return {
      fields: [
        { key: 'id_company', label: 'Empresa ID'},
        { key: 'usuario_nome', label: 'Usuario'},
        { key: 'usuario_email', label: 'E-mail'},
        { key: 'empresa', label: 'Empresa'},
        { key: 'deleted_at', label: 'Excluído'},
      ],
      currentPage: 1,
      perPage: 30

    }
  },
  mounted() {
    this.$store.dispatch('usuarios_cliente/listaUsuarios')
  },
  computed: {
    ...mapState({
      usuarios: (state) => state.usuarios_cliente.usuarios
    }),
    usuariosFiltrados() {

    }
  },
  methods: {
    paginacao() {
      this.$store.commit('usuarios_cliente/STORE_PAGINACAO', this.currentPage)
       this.$store.dispatch('usuarios_cliente/listaUsuarios')
    }
  }
}
</script>

<style>

</style>
