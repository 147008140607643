<template>
  <div>
    <b-row class="mb-1" align-h="end">
      <b-col md="5">
        <label
        ><feather-icon icon="SearchIcon"></feather-icon>
          Procurar</label
        >
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filtro" debounce="500" placeholder="Insira um e-mail..." />
          <b-input-group-append>
            <b-button :disabled="!filtro" @click="filtro = ''"> Limpar </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import {
  BIcon,
  BButton,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BFormDatepicker,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";
import moment from "moment";

export default {
  directives: { Ripple, "b-toggle": VBToggle },
  components: {
    BIcon,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    vSelect,
    VBToggle,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BFormSelect,
  },
  props: ['fornecedorSelecionado'],
  data() {
    return {
      filtro: '',
      periodo: 3,
    };
  },

  mounted() {
    
  },
  watch: {
    filtro() {
      this.buscaEmail();
    },
  },

  methods: {
    buscaEmail() {
      console.log(this.filtro);
      this.$store.commit('usuarios_cliente/STORE_BUSCA_EMAIL', this.filtro)
      this.$store.dispatch('usuarios_cliente/listaUsuarios')
    }

  },
  filters: {
    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
  },
};
</script>
